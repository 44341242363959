import { Injectable } from '@angular/core';
import { ProspectApiService } from '@core/services/api.services/prospect-api.service';
import { UserApiService } from '@core/services/api.services/user-api.service';
import { GlobalStateService } from '@core/services/global.state.service';
import { ProspectCreate } from '@shared/models/prospect-create';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateProspectStateService {

  constructor(
    private service: ProspectApiService,
  ) { }

  createProspect(prospect: ProspectCreate): Observable<any> {
    return this.service.createProspect(prospect);
  }
}
