<div>
  <div fxLayout
       class="header">
    <div class="title"
         fxFlex>{{data.dialogTitle}}</div>
    <div class="sub-title">{{data.dialogSubTitle}}</div>
    <div fxFlex="16px"
         class="close"
         (click)="dialogRef.close()">
      <i class="icon-cross"></i>
    </div>
  </div>
  <div *ngIf="data.dialogType === 'create_conversation'">
    <app-create-conversation-wrapper (closeDialog)="closeDialog()"></app-create-conversation-wrapper>
  </div>
  <div *ngIf="data.dialogType === 'task'">
    <app-create-task [preselectedMembers]="[preselectedMember]"
                     [preselectedAccounts]="preselectedAccounts"
                     (closeDialog)="closeDialog()"></app-create-task>
  </div>
  <div *ngIf="data.dialogType === 'create_note'">
    <app-create-note (closeDialog)="closeDialog()"></app-create-note>
  </div>
  <div *ngIf="data.dialogType === 'sale'">
    <app-create-sales (closeDialog)="closeDialog()"></app-create-sales>
  </div>
  <div *ngIf="data.dialogType === 'taskTemplate'">
    <app-task-template-modal-dialog (closeDialog)="closeDialog()"
                                    [Id]="data.id"></app-task-template-modal-dialog>                                    
  </div>
  <div *ngIf="data.dialogType === 'create_prospect'">
    <app-create-prospect (closeDialog)="closeDialog()"
                                    [Id]="data.id"></app-create-prospect>                                    
  </div>
  <div *ngIf="data.dialogType === 'offerTemplate'">
    <app-offer-template-model-dialog (closeDialog)="closeDialog()" [Id]="data.id"></app-offer-template-model-dialog>
  </div>
</div>
