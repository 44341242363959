export const FileTypeEnum = {
  Ecm: 'Ecm',
  Crm: 'Crm',
  Email: 'Email',
  Html: 'Html',
  Xls: 'Xls',
  branding: 'BrandingImage',
  Offer: 'BannerImage',
  OfferXls: 'OfferXls',
};
