<ng-container [ngSwitch]="conversationType">
  <app-create-conversation *ngSwitchCase="ConversationType.conversation"
                           (conversationTypeChange)="conversationTypeChanged($event)"
                           (closeDialog)="onCloseDialog()"></app-create-conversation>
  <app-create-opportunity *ngSwitchCase="ConversationType.opportunity"
                          (conversationTypeChange)="conversationTypeChanged($event)"
                          (closeDialog)="onCloseDialog()"></app-create-opportunity>
  <app-create-concern *ngSwitchCase="ConversationType.concern"
                      (conversationTypeChange)="conversationTypeChanged($event)"
                      (closeDialog)="onCloseDialog()"></app-create-concern>
</ng-container>
