<div class="sm-font-container">
  <form [formGroup]="form">
    <div>
      <mat-form-field appearance="outline"
                      [style.width.%]="100">
        <mat-label>Template</mat-label>
        <mat-select formControlName="templateId">
          <mat-option *ngFor="let template of taskTemplatesOptions"
                      [value]="template.value"
                      [disabled]="template.disabled">{{template.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout>
      <div fxFlex="65"
           class="left-col">
        <mat-form-field appearance="outline"
                        [style.width.%]="100">
          <mat-label>Task Type</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let type of TypeOptions"
                        [value]="type.value">{{type.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="">
        <mat-form-field appearance="outline">
          <mat-label>Priority</mat-label>
          <mat-select formControlName="priority">
            <mat-option *ngFor="let priority of PriorityOptions"
                        value="{{priority.value}}">{{priority.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-form-field appearance="outline"
                      [style.width.%]="100">
        <mat-label>Description</mat-label>
        <textarea matInput
                  placeholder="Description Text Here"
                  formControlName="description"></textarea>
      </mat-form-field>
    </div>
    <div fxLayout>
      <div fxFlex="40"
           class="calendar">
        <mat-form-field appearance="outline"
                        [style.width.%]="100">
          <mat-label>Due Date</mat-label>
          <input matInput
                 [matDatepicker]="picker"
                 formControlName="dueDate"
                 [min]="todayDate">
          <mat-datepicker-toggle matSuffix
                                 [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
        <mat-form-field fxFlex="60" appearance="outline" class="assignedId-container">
            <mat-label>Assigned To</mat-label>
            <input type="text" [(ngModel)]="selectedUser" 
              formControlName="assigneeId" matInput 
              placeholder="Assigned To"
              [matAutocomplete]="auto" 
              [defaultValue]=""
              matAutocompletePosition="below"
              required="true"
              >
              <mat-icon matSuffix ><i class="icon-search"></i></mat-icon>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
              <mat-option *ngFor="let user of users$ | async" [value]="user"
                [disabled]="user.status === 'Inactive'"
                [ngStyle]="{'color': user.status === 'Inactive' ? 'gray' : 'initial'}">               
                {{ user.label}}
              </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <app-add-email-template *ngIf="isEmailType()"
                            [preselected]="taskEmails"
                            (chosenEmail)="addEmail($event)"></app-add-email-template>
    <app-link-item [links]="['prospect', 'account', 'conversation']"
                   [preselectedMembersProspects]="[preselectedMember]"
                   [preselectedAccounts]="preselectedAccounts"
                   [preselectedConversation]="preselectedConversation"
                   (chosenInstances)="addInstance($event)"></app-link-item>
    <app-upload-file [parentForm]="form"
                     [fileType]="FileTypeEnum.Crm"
                     [readOnly]="false"></app-upload-file>
    <div>
      <cm-button color="primary"
                 class="record-btn"
                 (click)="form.valid && recordTaskClick()"
                 [disabled]="!form.valid">
        Record
      </cm-button>
    </div>
  </form>
</div>
