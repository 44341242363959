<div *ngIf="gradientTitle"
     class="table-header separate {{filterClassName}}">
  <div *ngIf="title"
       class="title gradient"
       fxLayoutAlign="start center">
    <span>{{title}}</span>
  </div>

  <app-table-filter *ngIf="filterRowDefs.length"
                    [filterRows]="filterRowDefs">
  </app-table-filter>
</div>

<div class="border shadow {{tableClassName}}">
  <div *ngIf="title && !gradientTitle"
       class="table-header"
       fxLayout="row"
       fxLayoutAlign="space-between center">
    <div class="title">
      <span>{{title}}</span>
    </div>
    <div *ngIf="actionBtnConfig?.isDisplayed"
         class="btn">
      <cm-button (click)="clickActionBtn($event)"
                 [disabled]="actionBtnConfig?.isDisabled"
                 [icon]="!actionBtnConfig?.iconType || actionBtnConfig?.iconType == 'mat-icon' ? actionBtnConfig.icon : null"
                 [color]="actionBtnConfig?.color">
        <i *ngIf="actionBtnConfig?.iconType == 'icon'"
           [ngClass]="actionBtnConfig?.icon"></i>
        {{actionBtnConfig?.name}}
      </cm-button>
    </div>
  </div>

  <ng-content select="[breadcrumbs]"></ng-content>
  <table mat-table
         matSort
         multiTemplateDataRows
         [matSortDisableClear]="true"
         [dataSource]="tableDataSource"
         (matSortChange)="sortTable($event)"
         [matSortDirection]="sortOrder"
         [matSortActive]="sortBy">


    <ng-container *ngFor="let tableColumn of tableColumns"
                  [matColumnDef]="tableColumn.name">

      <ng-container *ngIf="tableColumn.isSortable; else notSortable">
        <th mat-header-cell
            *matHeaderCellDef
            [mat-sort-header]="tableColumn.name"
            [style.width]="tableColumn.width"
            [arrowPosition]="tableColumn.position === 'right' ? 'before' : 'after'"
            [ngClass]="tableColumn.className">
          {{ tableColumn.hideHeader ? '' : tableColumn.name}}
        </th>
      </ng-container>

      <ng-template #notSortable>
        <th mat-header-cell
            *matHeaderCellDef
            [style.width]="tableColumn.width"
            [class.text-right]="tableColumn.position == 'right'"
            [ngClass]="tableColumn.className">
          {{ tableColumn.hideHeader ? '' : tableColumn.name}}
        </th>
      </ng-template>

      <td mat-cell
          *matCellDef="let element"
          [class.text-right]="tableColumn.position == 'right'"
          [style.width]="tableColumn.width"
          [ngClass]="tableColumn.className">
        <ng-container [ngSwitch]="tableColumn.templateName">
          <ng-container *ngSwitchCase="'checkbox'">
            <mat-checkbox (click)="$event.stopPropagation(); selectCheckbox(element)"
                          [disabled]="element.isDisabled || element.required"
                          [(ngModel)]="element.checked"
                          name="{{element.id}}">
            </mat-checkbox>
          </ng-container>
          <ng-container *ngSwitchCase="'priority'">
            <div fxLayout="row"
                 fxLayoutAlign="start center">
              <div [ngClass]="element | dataPropertyGetter: tableColumn.dataKey"
                   class="priority-icon"></div>
              <span *ngIf="show"> {{element | dataPropertyGetter: tableColumn.dataKey}} </span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'description'">
            <div class="description-column-main">
              <div class="clamped-text">
                {{element | dataPropertyGetter: tableColumn.dataKey | dynamicPipe: tableColumn.pipe | async}}
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'tooltip'">
            <div class="description-column">
              <div [clamp]="3"
                   matTooltipPosition="above"
                   matTooltip="{{element | dataPropertyGetter: tableColumn.dataKey | dynamicPipe: tableColumn.pipe | async}}">
                {{element | dataPropertyGetter: tableColumn.dataKey | dynamicPipe: tableColumn.pipe | async }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'file'">
            <div class="file-column">
              <span *ngIf="element.isFile"><i class="icon icon-file"></i></span>
              <span *ngIf="!element.isFile"><i class="icon icon-folder"></i></span>
              {{element | dataPropertyGetter: tableColumn.dataKey}}
              <span *ngIf="element.required"><i class="icon icon-lock"></i></span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'conversationType'">
            <ng-container [ngSwitch]="element | dataPropertyGetter: tableColumn.dataKey">
              <ng-container *ngSwitchCase="conversationType.opportunity"><i class="icon icon-star"></i></ng-container>
              <ng-container *ngSwitchCase="conversationType.conversation"><i class="icon icon-conversation"></i>
              </ng-container>
              <ng-container *ngSwitchCase="conversationType.concern"><i class="icon icon-alert"></i></ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'createdByOrAssignee'">
            {{getFullName(element  | dataPropertyGetter: tableColumn.dataKey)}}
          </ng-container>
          <ng-container *ngSwitchCase="'link'">
            <a>{{element | dataPropertyGetter: tableColumn.dataKey}}</a>
          </ng-container>

          <ng-container *ngSwitchCase="'region'">
            <span (click)="expandedElement = expandedElement === element ? null : element; isExpanded = true"
                  *ngIf="element[tableColumn.externalKey]?.length">
              <span class="icon-container">
                <i *ngIf="!expandedElement"
                   class="action-icon icon-arrow_down"></i>
                <i *ngIf="expandedElement"
                   class="action-icon icon-arrow_up"></i>
                {{element | dataPropertyGetter: tableColumn.dataKey}}
              </span>
            </span>
            <span *ngIf="!element[tableColumn.externalKey]?.length">
              {{element | dataPropertyGetter: tableColumn.dataKey}}
            </span>
          </ng-container>

          <ng-container *ngSwitchCase="'import'">
            <div fxLayout="row"
                 fxLayoutAlign="center center"
                 fxLayoutGap="5px">
              <span *ngIf="element.isImported"
                    class="icon-{{element | dataPropertyGetter: tableColumn.dataKey}}"></span>
              <span (click)="$event.stopPropagation(); startImport(element)"
                    class="icon-import"></span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'import-delete'">
            <div fxLayout="row"
                 fxLayoutAlign="end center"
                 fxLayoutGap="5px">
              <span *ngIf="!element.imported && element.isReadyForImport()"
                    (click)="$event.stopPropagation(); startImport(element)"
                    class="icon-upload"></span>
              <span (click)="$event.stopPropagation(); download(element)"
                    class="icon-import"></span>
              <span *ngIf="!element.isImportInProcess()"
                    (click)="$event.stopPropagation(); delete(element)"
                    class="icon-trash"></span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'edit-delete'">
            <div fxLayout="row"
                 fxLayoutAlign="end center"
                 fxLayoutGap="5px">
              <span (click)="$event.stopPropagation(); edit(element, $event)"
                    class="icon-edit"></span>
              <span (click)="$event.stopPropagation(); delete(element)"
                    class="icon-trash"></span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'delete'">
            <span (click)="$event.stopPropagation(); delete(element)"
                  class="icon-trash"></span>
          </ng-container>
          <ng-container *ngSwitchCase="'sendactivation'">
            <span *ngIf="element.isReadyForActivation()" (click)="$event.stopPropagation(); sendactivation(element)"
                  class="icon-upload"> Send Activation</span>
          </ng-container>
          <ng-container *ngSwitchCase="'clickableColumn'">
            <a class="clickable-column"
               target="_blank"
               [href]="tableColumn.getLinkUrl(element)"
               fxLayoutAlign="space-between">
              {{element | dataPropertyGetter: tableColumn.dataKey}}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="'datetime'">
            <div class="date-column"
                 [ngClass]="isOverdue(element)">
              {{element | dataPropertyGetter: tableColumn.dataKey | dynamicPipe: tableColumn.pipe | async }}
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'percent'">
            <div class="description-column">
              {{element | dataPropertyGetter: tableColumn.dataKey | number: '.2-2'}}%
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'member'">
            <app-member-list [ngClass]="tableColumn.className"
                             *ngIf="!element.isLinkHidden"
                             [readOnly]="tableColumn.areActionsReadOnly"
                             [linksList]="element | dataPropertyGetter: tableColumn.dataKey"
                             [memberProfile]="element | dataPropertyGetter: tableColumn.dataKey"></app-member-list>
          </ng-container>
          <!--          TODO: this template (actionBtn) is kept for future-->
          <ng-container *ngSwitchCase="'actionBtn'">
            <div *ngIf="show && isColumnVisible(tableColumn)">
              <i class="icon-trash btn"
                 (click)="delete(element)"></i>
              <i class="icon-edit btn"
                 (click)="edit(element, $event)"></i>
            </div>
            <div *ngIf="!show && isColumnVisible(tableColumn)">
              <app-three-dots-menu color="primary"
                                   (click)="$event.stopPropagation()">
                <ng-container menu>
                  <button class="mat-menu-item table-btn-delete"
                          fxLayout
                          fxLayoutGap="10px"
                          (click)="delete(element)">
                    <i class="icon-trash"></i>
                    <span>Delete</span>
                  </button>
                  <button class="mat-menu-item"
                          fxLayout
                          fxLayoutGap="10px"
                          (click)="edit(element, $event)">
                    <i class="icon-edit"></i>
                    <span>Edit</span>
                  </button>
                </ng-container>
              </app-three-dots-menu>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'ecm-btns'">
            <app-three-dots-menu color="primary"
                                 *ngIf="!element.isDisabled || element.isAdmin"
                                 (click)="$event.stopPropagation()">
              <ng-container menu>
                <button *ngIf="element.isFile"
                        class="mat-menu-item"
                        fxLayout
                        fxLayoutGap="10px"
                        (click)="download(element)">
                  <i class="icon-import"></i>
                  <span>Download</span>
                </button>
                <button class="mat-menu-item"
                        fxLayout
                        fxLayoutGap="10px"
                        (click)="edit(element, $event)">
                  <i class="icon-edit"></i>
                  <span>Edit</span>
                </button>
                <button class="mat-menu-item table-btn-delete"
                        [disabled]="element.required || !element.hasAccess"
                        fxLayout
                        fxLayoutGap="10px"
                        (click)="delete(element)">
                  <i class="icon-trash"></i>
                  <span>Delete</span>
                </button>
              </ng-container>
            </app-three-dots-menu>
          </ng-container>
          <ng-container *ngSwitchCase="'download'">
            <app-three-dots-menu (click)="$event.stopPropagation()"
                                 *ngIf="!element.isDisabled"
                                 color="primary">
              <ng-container menu>
                <button (click)="download(element)"
                        class="mat-menu-item"
                        fxLayout
                        fxLayoutGap="10px">
                  <i class="icon-import"></i>
                  <span>Download</span>
                </button>
              </ng-container>
            </app-three-dots-menu>
          </ng-container>
          <ng-container *ngSwitchCase="'attachment'">
            <i class="icon-attachment"
               *ngIf="(element | dataPropertyGetter: tableColumn.dataKey).length"></i>
          </ng-container>
          <ng-container *ngSwitchCase="'currency'">
            {{element | dataPropertyGetter: tableColumn.dataKey | currency :'USD' : 'symbol' : '1.2-2' | dynamicPipe: tableColumn.pipe : element | async }}
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{element | dataPropertyGetter: tableColumn.dataKey | dynamicPipe: tableColumn.pipe | async}}
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedAccordion">
      <td mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns?.length">
        <div class="accordion-element-detail">
          <app-region-builder *ngIf="isExpanded"
                              [tableConfig]="tableColumns"
                              (clickAction)="accordionClick($event)"
                              [data]="element.data">
          </app-region-builder>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row
        [class.parent-row]="expandedElement === row"
        *matRowDef="let row; columns: displayedColumns;"
        (click)="clickRow(row)"
        [class.disabled]="row.isDisabled"
        [ngClass]="isClickable() ? 'pointer' : ''"></tr>

    <tr *matNoDataRow>
      <td class="emptyMsg"
          [colSpan]="tableColumns.length">{{emptyMsg}}</td>
    </tr>

    <tr mat-row
        [class.full-expanded-row]="expandedElement === row"
        *matRowDef="let row; columns: ['expandedAccordion']"
        class="accordion-row"></tr>

  </table>

  <mat-paginator *ngIf="isPageable && tableDataSource.data.length"
                 [length]="totalCount"
                 [pageIndex]="page"
                 [pageSizeOptions]="paginationSizes"
                 [pageSize]="defaultPageSize"
                 (page)="pageChange($event)"
                 showFirstLastButtons>
  </mat-paginator>

  <div class="footer"
       *ngIf="footerTemplate">
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </div>

</div>
