import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { GlobalStateService } from '@core/services/global.state.service';
import { getCurrentThemeUrl, getSiteId } from '@config/org.config';
import { addThemeCss } from '@shared/utils';

@Component({
  selector: 'app-platform',
  templateUrl: './platform-layout.component.html',
  styleUrls: ['./platform-layout.component.scss']
})
export class PlatformLayoutComponent implements OnInit {
  constructor(
    private router: Router,
    private globalStateService: GlobalStateService
  ) { }


  ngOnInit(): void {
    this.globalStateService.themeBuilderType$
      .pipe(
        take(1)
      )
      .subscribe(type => {
        const link = this.getLink(type);

        addThemeCss(`${link}/styles/custom_theme_colors.css`);
        addThemeCss(`${link}/styles/custom.css`);
      });
  }

  private getLink(type: string): string {
    let url = getCurrentThemeUrl();
    return type === 'client' ? url : url += `/${getSiteId()}`;
  }
}
