import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalDialogData } from '@shared/interfaces/ModalDialogData.interface';
import { HeaderIconModalDialogComponent } from '@shared/components/header-icon-modal-dialog/header-icon-modal-dialog.component';
import { HeaderIconModalDialogModule } from '@shared/components/header-icon-modal-dialog/header-icon-modal-dialog.module';
import { PermissionStringEnum } from '@shared/enums';

@Component({
  selector: 'app-header-action-item',
  templateUrl: './header-action-item.component.html',
  styleUrls: ['./header-action-item.component.scss']
})
export class HeaderActionItemComponent implements OnInit {

  @Input() actionType = '';
  @Input() actionText = '';
  @Input() hasPermission = Boolean;

  constructor(public dialog: MatDialog) { }

  dialogData: ModalDialogData;
  openDialog(): void {
    const dialogRef = this.dialog.open(HeaderIconModalDialogComponent, {
      width: '550px',
      data: this.dialogData,
      position: {top: '12%'},
      disableClose: true
    });
  }


  ngOnInit(): void {
    this.dialogData = {dialogTitle:  this.actionText, buttonTitle: 'Record', dialogType: this.actionType};
  }
}
