export enum GroupTypeEnum {
  CRMAssignment = 1,
  ECMFolderPermission = 2,
  All = 3
}
export const GroupTypeStringEnum = {
  CRMASSIGNMENT: 'CRM Assignment',
  ECMFOLDERPERMISSION: 'ECM Folder Permission',
  All: 'All'
};
