import { Injectable } from '@angular/core';
import { ApiClient } from '../api-client.http.service';
import { ProspectUrls } from '@core/constants';
import { Observable } from 'rxjs';
import { ProspectCreate } from '@shared/models/prospect-create';
import { MemberProfileDto } from '../dto';

@Injectable({
  providedIn: 'root'
})
export class ProspectApiService {

  constructor(private api: ApiClient) { }

  createProspect(createProspect: ProspectCreate): Observable<ProspectCreate> {
    return this.api.post<ProspectCreate>(
      ProspectUrls.Prospect, createProspect
    );
  }

  deleteProspectProfile(ProspectId: number): Observable<MemberProfileDto> {
    return this.api.delete<MemberProfileDto>(ProspectUrls.deleteProspect(ProspectId));
  }
}
