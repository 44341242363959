<div class="confirmation-modal">
  <div mat-dialog-title class="title">
    {{title}}
  </div>

  <div mat-dialog-content class="message">
    <p>{{message}}</p>
  </div>

  <div fxLayout fxLayoutAlign="flex-end center" fxLayoutGap="40px" class="buttons-list">
    <a *ngIf="showCancelBtn" class="cancel-link" (click)="onDismiss()">Cancel</a>
    <cm-button (click)="onConfirm()">{{submitButton}}</cm-button>
  </div>
</div>

