import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Error404PageComponent } from './pages/error404-page/error404-page.component';
import { CustomTableModule } from '@shared/components/custom-table/custom-table.module';
import { FlexModule } from '@angular/flex-layout';
import { CustomDatePipe } from '@shared/pipes/custom-date.pipe';
import { EmptyFieldPlaceholderPipe } from '@shared/pipes/empty-field-placeholder.pipe';
import { CheckPermissionDirective } from '@core/helpers/check-permission.directive';
import { CheckRoleDirective } from '@core/helpers/check-role.directive';
import { RemoveInnerHtmlPipe } from '@shared/pipes/remove-inner-html.pipe';
import { LeadingZerosPipe } from '@shared/pipes/leading-zeros.pipe';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    CustomTableModule,
    FlexModule,
  ],
  declarations: [
    Error404PageComponent,
    CustomDatePipe,
    EmptyFieldPlaceholderPipe,
    LeadingZerosPipe,
    RemoveInnerHtmlPipe,
    CheckPermissionDirective,
    CheckRoleDirective,
  ],
  exports: [
    CommonModule,
    CustomDatePipe,
    EmptyFieldPlaceholderPipe,
    LeadingZerosPipe,
    RemoveInnerHtmlPipe,
    CheckPermissionDirective,
    CheckRoleDirective,
  ],
  providers: [EmptyFieldPlaceholderPipe],
})
export class SharedModule {}
