import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { GlobalStateService } from '@core/services/global.state.service';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { UserBranchModel } from '@shared/models/user-Branch.model';
import { ContactTypeEnum } from '@shared/enums';
import { SearchAccountOptionsModel, SearchConversationOptionsModel, SearchMemberOptionsModel } from '@shared/models';
import { LeadTypeEnum } from '@shared/enums/lead-type.enum';
import {
  BusinessPhoneTypeOptions,
  BusinessRoleTypeOptions, BusinessTypeOptions, LeadTypeOptions, PhoneTypeOptions,
  PrefferedContactOptions, ProspectStatusOptions, SaluationOptions
} from '../create-prospect.config';
import { BusinessTypeEnum } from '@shared/enums/business-type.enum';
import { BusinessPhoneTypeEnum, PhoneTypeEnum } from '@shared/enums/phone-type.enum';
import { BusinessRoleTypeEnum } from '@shared/enums/business-role-type.enum';
import { SaluationEnum } from '@shared/enums/saluation.enum';
import { CreateProspectStateService } from '../create-prospect.state.service';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProspectTypeEnum } from '@shared/enums/prospect-type.enum';
import { ProspectStatusEnum } from '@shared/enums/prospect-status.enum';
import { ProspectCreate } from '@shared/models/prospect-create';
import { SearchMemberProspectOptionsModel } from '@shared/models/search.member.prospect.options.model';
import { MemberProspectInstancesModel } from '@shared/models/memberprospectinstances.model';
import { BranchDto } from '@core/services/dto';
import { Router } from '@angular/router';
import { AppConfig } from '@config/app.config';

@Component({
  selector: 'app-create-prospect',
  templateUrl: './create-prospect.component.html',
  styleUrls: ['./create-prospect.component.scss']
})

export class CreateProspectComponent extends autoUnsubscribeMixin()
  implements OnInit {
  form: FormGroup;
  branches: BranchDto[];
  prospect: ProspectCreate;
  formLinks: SearchMemberProspectOptionsModel[] = [];
  ProspectTypeEnum = ProspectTypeEnum;
  userBranchOptions: UserBranchModel[];
  LeadTypeOptions = LeadTypeOptions;
  PhoneTypeOptions = PhoneTypeOptions;
  BusinessPhoneTypeOptions = BusinessPhoneTypeOptions;
  BusinessTypeOptions = BusinessTypeOptions;
  ProspectStatusOptions = ProspectStatusOptions;
  private orginalBusinessRoleTypeOptions = BusinessRoleTypeOptions;
  public BusinessRoleTypeOptions = [];
  PrefferedContactOptions = PrefferedContactOptions;
  SaluationOptions = SaluationOptions;
  GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  viewInit = false;
  cuName = '';
  defaultBranch: UserBranchModel;

  @Input() preselectedMembers: SearchMemberOptionsModel[] = [];
  @Input() preselectedConversation: SearchConversationOptionsModel;
  @Input() preselectedAccounts: SearchAccountOptionsModel[] = [];
  @Input() preselectedMembersProspects: SearchMemberProspectOptionsModel[] = [];

  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Input() Id?;

  constructor(
    private fb: FormBuilder,
    private state: CreateProspectStateService,
    private toast: CustomSnackbarService,
    private router: Router,
    private globalStateService: GlobalStateService,
  ) {
    super();
    this.form = this.fb.group({
      prospectType: [null, Validators.required],
      branchId: [null],
      prospectStatus: [ProspectStatusEnum[ProspectStatusEnum.Active]],
      // Retailfields
      retailLead: [LeadTypeEnum['']],
      retailPreferredContact: [ContactTypeEnum['']],
      retailSaluation: [SaluationEnum[''], Validators.required],
      retailFirstName: [null],
      retailLastName: [null],
      retailNickName: [null],
      retailEmail: [null],
      retailPhoneNumber: [null],
      retailPhone: [PhoneTypeEnum['']],
      retailConsent: [false],
      // Businessfields
      businessConsent: [false],
      businessPreferredContact: [ContactTypeEnum['']],
      businessLead: [LeadTypeEnum['']],
      businessType: [BusinessTypeEnum['']],
      businessName: [null],
      businessEmail: [null],
      businessPhoneNumber: [null],
      businessPhone: [BusinessPhoneTypeEnum['']],
      contactRole: [BusinessRoleTypeEnum['']],
      contactNickName: [null],
      contactSaluation: [SaluationEnum['']],
      contactFirstName: [null],
      contactLastName: [null],
      contactEmail: [null],
      contactPhoneNumber: [null],
      contactPhone: [BusinessPhoneTypeEnum['']],
    });

  }

  ngOnInit(): void {

    this.globalStateService.getBranch();

    this.globalStateService.userBranches$().subscribe({
      next: (data) => {
        this.userBranchOptions = data;
        this.defaultBranch = this.userBranchOptions.find(branch => branch.isDefault === true);
        this.form.patchValue({
          branchId: this.defaultBranch?.value
        });
      },
      error: (error) => {
        console.error('Error:', error);
      }
    });

    this.globalStateService.getSiteDetails().subscribe((data: any) => {
      this.cuName = data.legalName;
    });

    this.form.valueChanges.subscribe(val => {
      const retailLead = 'retailLead';
      const retailFirstName = 'retailFirstName';
      const retailConsent = 'retailConsent';
      const branchId = 'branchId';
      const retailEmail = 'retailEmail';
      const retailPhoneNumber = 'retailPhoneNumber';
      const businessLead = 'businessLead';
      const businessName = 'businessName';
      const contactRole = 'contactRole';
      const contactFirstName = 'contactFirstName';
      const businessConsent = 'businessConsent';
      const businessEmail = 'businessEmail';
      const retailPreferredContact = 'retailPreferredContact';
      const businessPhoneNumber = 'businessPhoneNumber';
      const contactEmail = 'contactEmail';
      const retailSaluation = 'retailSaluation';
      const contactPhoneNumber = 'contactPhoneNumber';
      const retailLastName = 'retailLastName';
      const retailNickName = 'retailNickName';
      const retailPhone = 'retailPhone';
      const businessPhone = 'businessPhone';
      const contactPhone = 'contactPhone';
      const businessPreferredContact = 'businessPreferredContact';

      if (val.prospectType === ProspectTypeEnum.Individual) {
        //#region New Validations
        this.form.controls[retailPreferredContact].setValidators([Validators.required]);
        if (val.retailPreferredContact === 'Email') {
          this.form.controls[retailEmail].setValidators([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,3}$'),
          ]);
          this.form.controls[retailPhone].clearValidators();
          this.form.controls[retailPhoneNumber].clearValidators();
          this.form.controls[retailEmail].updateValueAndValidity({ emitEvent: false });
          this.form.controls[retailPhoneNumber].updateValueAndValidity({ emitEvent: false });
          this.form.controls[retailPhone].updateValueAndValidity({ emitEvent: false });
        }
        if (val.retailPreferredContact === 'Phone') {
          this.form.controls[retailPhoneNumber].setValidators([
            Validators.required,
            Validators.pattern(/^\d+$/), // Only numbers
            Validators.minLength(5),
            Validators.maxLength(20)
          ]);
          this.form.controls[retailEmail].clearValidators();
          this.form.controls[retailPhone].setValidators([Validators.required]);
          this.form.controls[retailEmail].updateValueAndValidity({ emitEvent: false });
          this.form.controls[retailPhoneNumber].updateValueAndValidity({ emitEvent: false });
          this.form.controls[retailPhone].updateValueAndValidity({ emitEvent: false });
        }
        //#endregion
        this.form.controls[retailLead].setValidators([Validators.required]);
        this.form.controls[retailFirstName].setValidators([Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)
        ]);
        this.form.controls[branchId].setValidators([Validators.required]);
        this.form.controls[retailConsent].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailFirstName].updateValueAndValidity({ emitEvent: false });
        this.form.controls[branchId].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailLead].updateValueAndValidity({ emitEvent: false });
      }
      if (val.prospectType === ProspectTypeEnum.Business) {
        this.form.controls[retailLead].clearValidators();
        this.form.controls[retailPreferredContact].clearValidators();
        this.form.controls[retailSaluation].clearValidators();
        this.form.controls[retailFirstName].clearValidators();
        this.form.controls[retailLastName].clearValidators();
        this.form.controls[retailNickName].clearValidators();
        this.form.controls[retailEmail].clearValidators();
        this.form.controls[retailPhoneNumber].clearValidators();
        this.form.controls[retailPhone].clearValidators();
        this.form.controls[retailConsent].clearValidators();
        this.form.controls[retailLead].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailPreferredContact].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailSaluation].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailFirstName].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailLastName].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailNickName].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailEmail].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailPhoneNumber].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailPhone].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailConsent].updateValueAndValidity({ emitEvent: false });
        this.form.controls[businessLead].setValidators([Validators.required]);
        this.form.controls[businessName].setValidators([Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)
        ]);
        this.form.controls[branchId].setValidators([Validators.required]);
        this.form.controls[contactRole].setValidators([Validators.required]);
        this.form.controls[contactFirstName].setValidators([Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)
        ]);
        this.form.controls[businessLead].updateValueAndValidity({ emitEvent: false });
        this.form.controls[businessName].updateValueAndValidity({ emitEvent: false });
        this.form.controls[branchId].updateValueAndValidity({ emitEvent: false });
        this.form.controls[contactRole].updateValueAndValidity({ emitEvent: false });
        this.form.controls[contactFirstName].updateValueAndValidity({ emitEvent: false });
        this.form.controls[businessConsent].updateValueAndValidity({ emitEvent: false });
        //#region new validations
        this.form.controls[businessPreferredContact].setValidators([Validators.required]);
        if (val.businessPreferredContact === 'Email') {
          this.form.controls[contactEmail].setValidators([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,3}$'),
          ]);
          this.form.controls[businessPhoneNumber].clearValidators();
          this.form.controls[contactPhoneNumber].clearValidators();
          this.form.controls[businessPhone].clearValidators();
          this.form.controls[contactEmail].updateValueAndValidity({ emitEvent: false });
          this.form.controls[businessPhone].updateValueAndValidity({ emitEvent: false });
          this.form.controls[contactPhoneNumber].updateValueAndValidity({ emitEvent: false });
          this.form.controls[businessPhoneNumber].updateValueAndValidity({ emitEvent: false });

        }
        if (val.businessPreferredContact === 'Phone') {
          this.form.controls[businessPhoneNumber].setValidators([
            Validators.required,
            Validators.pattern(/^\d+$/), // Only numbers
            Validators.minLength(5),
            Validators.maxLength(20)
          ]);
          this.form.controls[contactPhoneNumber].setValidators([
            Validators.required,
            Validators.pattern(/^\d+$/), // Only numbers
            Validators.minLength(5),
            Validators.maxLength(20)
          ]);
          this.form.controls[businessPhone].setValidators([Validators.required]);
          this.form.controls[businessEmail].clearValidators();
          this.form.controls[contactEmail].clearValidators();
          this.form.controls[businessEmail].updateValueAndValidity({ emitEvent: false });
          this.form.controls[contactEmail].updateValueAndValidity({ emitEvent: false });
          this.form.controls[businessPhoneNumber].updateValueAndValidity({ emitEvent: false });
          this.form.controls[contactPhoneNumber].updateValueAndValidity({ emitEvent: false });
          this.form.controls[businessPhone].updateValueAndValidity({ emitEvent: false });

        }
        //#endregion

        if (val.businessEmail) {
          this.form.controls[contactEmail].setValidators([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,3}$'),
          ]);
          this.form.controls[contactEmail].updateValueAndValidity({ emitEvent: false });
        }
        if (val.businessPhoneNumber) {
          this.form.controls[businessPhone].clearValidators();
          this.form.controls[businessPhone].setValidators([Validators.required]);
          this.form.controls[businessPhone].updateValueAndValidity({ emitEvent: false });
        }
        if (val.contactPhoneNumber) {
          this.form.controls[contactPhone].clearValidators();
          this.form.controls[contactPhone].setValidators([Validators.required]);
          this.form.controls[contactPhone].updateValueAndValidity({ emitEvent: false });
        }
      }
    });

  }

  add(): void {
    this.prospect = new ProspectCreate(this.form.value);
    if (this.formLinks?.length) {
      this.formLinks.forEach(element => {
        if (element.isProspectInfo === true) {
          element.type = 'Prospect';
        } else if (element.isProspectInfo === false) {
          element.type = 'Member';
        }
        else {
          element.type = element.type;
        }

      });
      this.prospect.ProspectMemberId = this.formLinks[0].ref;
    }
    this.prospect.links = this.formLinks;

    this.state.createProspect(this.prospect).subscribe(data => {
      this.toast.success('Prospect successfully added');
      if ('id' in data) {
        this.closeDialog.emit();
        this.router.navigate([`/${AppConfig.routes.prospectProfile.main}`, data.id]);
      }
    }, (error: HttpErrorResponse) => {
      console.log('error', error);
    });
  }

  addInstance(links: SearchMemberProspectOptionsModel[]): void {
    const acc = [];
    links.forEach(item => {
      acc.push(new MemberProspectInstancesModel(item));
    });
    this.formLinks = acc;
  }


  showToast(data?): void {
    if ('id' in data) {
      this.toast.success('Template successfully saved');
      this.closeDialog.emit();
    }
    else {
      this.toast.fail(data.response);
      this.closeDialog.emit();
    }
  }

  cancel(): void {
    this.closeDialog.emit();
  }

  public updateBusinessRoleOptions(event: any): void {
    const formValue = this.form.value;
    this.BusinessRoleTypeOptions = [];
    if (formValue.businessType) {
      switch (formValue.businessType) {
        case this.BusinessTypeOptions[0].value:
          // SoleProprietorship
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[0]);
          break;
        case this.BusinessTypeOptions[1].value:
          // Partnership
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[1]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[2]);
          break;
        case this.BusinessTypeOptions[2].value:
          // Association
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[0]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[2]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[3]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[4]);
          break;
        case this.BusinessTypeOptions[3].value:
          // Corporation
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[0]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[2]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[3]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[4]);
          break;
      }
    }
  }

  get isFormValid(): boolean {
    return this.form.valid;
  }

}
