import {
  AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild,
  OnChanges, SimpleChanges
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { TableColumnModel } from '@shared/components/custom-table/models/TableColumn.model';
import { Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { TableFilterRowDefDirective } from './directives/table-filter-row-def.directive';
import { ActionButtonModel } from '@shared/components/custom-table/models/action-button.model';
import { ConversationType } from '@shared/configs/conversation-types.config';

type sortOrderType = 'asc' | 'desc';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  currentScreenWidth = '';
  flexMediaWatcher: Subscription;
  conversationType = ConversationType;
  expandedElement: null;
  isExpanded = false;

  public tableDataSource = new MatTableDataSource([]);
  public displayedColumns: string[];
  public show = true;
  public page: number;

  @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  @Input() isPageable = false;
  @Input() isSortable = false;
  @Input() isFilterable = false;
  @Input() isTabletView = false;
  @Input() tableColumns: TableColumnModel[];
  @Input() paginationSizes: number[] = [5, 10, 15];
  @Input() defaultPageSize = this.paginationSizes[1];
  @Input() sortBy: string | string[] = '';
  @Input() sortOrder: sortOrderType = 'asc';
  @Input() totalCount: number;
  @Input() pageIndex: number;
  @Input() emptyMsg: string;
  @Input() title: string;
  @Input() tableClassName: string;
  @Input() gradientTitle: boolean;
  @Input() actionBtnConfig: ActionButtonModel;
  @Input() footerTemplate: TemplateRef<any>;
  @Input() hasTableHorizontalScroll: boolean;
  @Input() filterClassName: string;

  @Output() sort: EventEmitter<any> = new EventEmitter<string | string[]>();
  @Output() pageChanged: EventEmitter<any> = new EventEmitter<PageEvent>();
  @Output() rowClickAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() accordionLinkClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickBtn: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickColumn: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickView: EventEmitter<any> = new EventEmitter<any>();
  @Output() import: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadElement: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedCheckbox: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendActivation: EventEmitter<any> = new EventEmitter<any>();
  @Input() parentData: any;
  @Input() set tableData(data: any[]) {
    this.tableDataSource.data = data || [];
  }

  @ContentChildren(TableFilterRowDefDirective, { read: TemplateRef })
  filterRowDefs: QueryList<TemplateRef<any>>;

  constructor(private mediaObserver: MediaObserver) {
    this.tableDataSource = new MatTableDataSource([]);
  }

  setupTable(): void {
    this.displayedColumns = this.tableColumns.map(
      (tableColumn: TableColumnModel) => tableColumn.name
    );
    this.show = true;
    if (['md', 'sm', 'xs'].includes(this.currentScreenWidth)) {
      this.show = !this.show;
      this.displayedColumns = [];
      this.tableColumns.forEach((tableColumn: TableColumnModel) => {
        if (!tableColumn.hide) {
          this.displayedColumns.push(tableColumn.name);
        }
      });
    }
  }

  ngOnInit(): void {
    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .subscribe((changes: MediaChange[]) => {
        const currentChange = changes[0];
        if (currentChange.mqAlias !== this.currentScreenWidth) {
          this.currentScreenWidth = currentChange.mqAlias;
          this.setupTable();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.currentValue !== undefined &&
      changes.pageIndex.currentValue - 1 !== this.page
    ) {
      this.page = changes.pageIndex.currentValue;
    }
    if (
      this.parentData === 0
    ) {
      this.page = this.parentData;
    }
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.tableDataSource.sort = this.matSort;
  }

  sortTable(sortParameters: Sort): void {
    const column = this.tableColumns.find(
      (column) => column.name === sortParameters.active
    );
    const columnValueForSort =
      column.sortColumnValue || (column.dataKey as string);

    sortParameters.active = columnValueForSort;
    this.sort.emit(sortParameters);
  }

  pageChange(state: PageEvent): void {
    this.page = state.pageIndex;
    this.pageChanged.emit(state);
  }

  isOverdue(element): string {
    if (element && element.dueDate) {
      return element.dueDate.indexOf('overdue') !== -1 ? 'overdue-date' : '';
    }
    return '';
  }

  clickRow(event): void {
    this.rowClickAction.emit(event);
  }

  accordionClick(event): void {
    this.accordionLinkClick.emit(event);
  }

  startImport(event): void {
    this.import.emit(event);
  }

  download(element): void {
    this.downloadElement.emit(element);
  }

  isClickable(): boolean {
    return this.rowClickAction.observers.length > 0;
  }

  clickActionBtn(event): void {
    this.clickBtn.emit(event);
  }

  selectCheckbox(element): void {
    setTimeout(() => {
      this.selectedCheckbox.emit(element);
    }, 0);
  }

  delete(data): void {
    this.clickDelete.emit(data);
  }

  clickedColumn(data): void {
    this.clickColumn.emit(data);
  }

  edit(data, event: Event): void {
    this.clickEdit.emit({ data, event });
  }

  getFullName(element): string {
    return `${element.firstName} ${element.lastName}`;
  }

  isColumnVisible(column: TableColumnModel): boolean {
    return column.visible === undefined || column.visible;
  }

  sendactivation(data): void {
    this.sendActivation.emit(data);
  }
}
