import { takeUntil } from 'rxjs/operators';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalStateService } from '@core/services/global.state.service';
import { ModalDialogData } from '@shared/interfaces/ModalDialogData.interface';
import { SearchMemberOptionsModel, SearchAccountOptionsModel } from '@shared/models';

@Component({
  selector: 'app-header-icon-modal-dialog',
  templateUrl: './header-icon-modal-dialog.component.html',
  styleUrls: ['./header-icon-modal-dialog.component.scss']
})
export class HeaderIconModalDialogComponent extends autoUnsubscribeMixin() implements OnInit {
  preselectedMember: SearchMemberOptionsModel;
  preselectedAccounts: SearchAccountOptionsModel[];

  constructor(
    public dialogRef: MatDialogRef<HeaderIconModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalDialogData,
    private globalState: GlobalStateService) {
    super();
  }
  ngOnInit(): void {
    this.initSubsriptionsForTask();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private initSubsriptionsForTask(): void {
    if (this.data.dialogType === 'task') {
      this.globalState.memberProfile$()
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(
          data => {
            if (data) {
              this.preselectedMember = new SearchMemberOptionsModel(data);
            }
          }
        );
      this.globalState.preselectedAccount$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(
          data => {
            if (data) {
              this.preselectedAccounts = data;
            }
          }
        );
    }
  }
}
