import { ProspectCreateDto } from '@core/services/dto/prospect-create.dto';
import { ProspectTypeEnum } from '@shared/enums/prospect-type.enum';
import { SearchMemberProspectOptionsModel } from './search.member.prospect.options.model';

export class ProspectCreate {
  // UserId?: string;
  // MemberBranchI?: string;
  ProspectMemberId?: string;
  BranchId?: string;
  BusinessName?: string;
  LeadType?: string;
  Consent?: boolean;
  BusinessEmail?: string;
  BusinessPhone?: string;
  FirstName?: string;
  LastName?: string;
  NickName?: string;
  SurName?: string;
  Email?: string;
  Phone?: string;
  ProspectMemberType?: string;
  ProspectStatus?: string;
  PhoneType?: string;
  businessType?: string;
  BusinessRoleType?: string;
  prefferedMethodToContact?: string;
  salutationType?: string;
  links?: SearchMemberProspectOptionsModel[];
  entityLinkType?: string;


  constructor(dto: ProspectCreateDto) {
    this.BusinessName = dto.businessName;
    this.BranchId = dto.branchId;
    this.LeadType = dto.prospectType.toString() === ProspectTypeEnum.Individual.toString() ? dto.retailLead : dto.businessLead;
    this.Consent = dto.prospectType.toString() === ProspectTypeEnum.Individual.toString() ? dto.retailConsent : dto.businessConsent;
    this.BusinessEmail = dto.businessEmail;
    this.BusinessPhone = dto.businessPhoneNumber;
    this.FirstName = dto.prospectType.toString() === ProspectTypeEnum.Individual.toString() ? dto.retailFirstName : dto.contactFirstName;
    this.LastName = dto.prospectType.toString() === ProspectTypeEnum.Individual.toString() ? dto.retailLastName : dto.contactLastName;
    this.NickName = dto.prospectType.toString() === ProspectTypeEnum.Individual.toString() ? dto.retailNickName : dto.contactNickName;
    this.Email = dto.prospectType.toString() === ProspectTypeEnum.Individual.toString() ? dto.retailEmail : dto.contactEmail;
    this.Phone = dto.prospectType.toString() === ProspectTypeEnum.Individual.toString() ? dto.retailPhoneNumber : dto.contactPhoneNumber;
    this.ProspectMemberType = dto.prospectType.toString();
    this.ProspectStatus = dto.prospectStatus;
    this.PhoneType = dto.prospectType.toString() === ProspectTypeEnum.Individual.toString() ? dto.retailPhone : dto.contactPhone;
    this.businessType = dto.businessType;
    this.BusinessRoleType = dto.contactRole;
    this.prefferedMethodToContact = dto.prospectType.toString() === ProspectTypeEnum.Individual.toString()
      ? dto.retailPreferredContact : dto.businessPreferredContact;
    this.salutationType = dto.prospectType.toString() === ProspectTypeEnum.Individual.toString()
      ? dto.retailSaluation : dto.contactSaluation;
    this.links = dto.links;
    this.entityLinkType = dto.entityLinkType;
    this.ProspectMemberId = dto.ProspectMemberId;
    this.BranchId = dto.branchId;
    this.ProspectStatus = dto.prospectStatus;
  }
}
